<template>
    <div>
        <page-title :heading="$t('analytic.lang_lineVoids')" :icon="icon"
                    :subheading="$t('analytic.lang_lineVoids')"></page-title>
        <div class="app-main__inner">
            <immediate-cancellation-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import ImmediateCancellationComponent
        from "../../../../components/analytics/warewanlytics/ImmediateCancellation/ImmediateCancellationComponent";
    export default {
        name: "index",
        components: {ImmediateCancellationComponent, PageTitle},
        data: () => ({
            icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
        })
    }
</script>

<style scoped>

</style>